import React from 'react';
import { Link } from 'react-router-dom';

export default class FeatureTwo extends React.Component {
    render(){
        return (
            <>
                <section  className="feature-one">
                    <div style={{backgroundColor:'#FF6F0F'}} className="container">
                        <div     className="row">

                            <div  className="col-xl-4 col-lg-4 wow  fadeInLeft" data-wow-delay="100ms">
                                {/* Feature One Single */}
                                <div className="feature-one__single">
                                    <div className="feature-one__top">
                                        <div className="feature-one__icon">
                                            <span className="icon-heart"></span>
                                        </div>
                                        <h3 className="feature-one__title"><Link style={{textDecoration:'none'}} to={process.env.PUBLIC_URL + `/about`}>Embracing Unity </Link></h3>
                                    </div>
                                    <p className="feature-one__text">Indian Sikh Community Italy: Embracing Unity as a Beacon of Strength and Harmony.</p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                                {/* Feature One Single */}
                                <div  className="feature-one__single">
                                    <div className="feature-one__top">
                                        <div className="feature-one__icon">
                                            <span className="icon-heart"></span>
                                        </div>
                                        <h3 className="feature-one__title"><Link style={{textDecoration:'none'}} to={process.env.PUBLIC_URL + `/about`}>Community of Sikh</Link></h3>
                                    </div>
                                    <p className="feature-one__text">Indian Sikh Community Italy: Thriving Together in Italy's Embrace.</p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 wow fadeInRight" data-wow-delay="300ms">
                                {/* Feature One Single */}
                                <div className="feature-one__single">
                                    <div className="feature-one__top">
                                        <div className="feature-one__icon">
                                            <span className="icon-help"></span>
                                        </div>
                                        <h3 className="feature-one__title"><Link style={{textDecoration:'none'}} to={process.env.PUBLIC_URL + `/about`}>Empowering Students</Link></h3>
                                    </div>
                                    <p className="feature-one__text">Empowering Students: Support from Indian Sikh Community Italy.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        )
    }
}