import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const  AboutOne =()=>  {
         const[language,setLanguage] = useState('english')
         const location = useLocation() 
 
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section   className="about-one about-three">
                    <div  className="container">
                        <div className="row">
                        {location.pathname === '/' ? 
                            <div className="col-xl-6">
                                <div className="about-one__left wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
                                    <div className="about-one__img-box">
                                        <div className="about-one__img">
                                            <img src={publicUrl+"assets/images/backgrounds/aboutus.jpg"} alt="" />
                                        </div>
                                        <div className="about-one__small-img wow zoomIn animated animated" data-wow-delay="500ms"
                                            data-wow-duration="2500ms">
                                            <img src={publicUrl+"assets/images/gallery/g6.jpg"} alt="" />

                                        </div>
                                    </div>
                                </div>
                            </div> : null }
                            {location.pathname === '/' ?   <div className="col-xl-6">
                                <div  className="about-one__right">
                                    <div className="about-one__right-content">
                                        <div className="section-title text-left">
                                            <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                                            <span className="section-title__tagline">About Us</span>
                                    </div>
                                           
                                            <h2 className="section-title__title">Indian Sikh Community Italy</h2>
                                        </div>
                                        <div style={{color:'#001d23'}} className="about-one__text"> {language === 'english' ? "Mr. Sukhdev Singh Kang founded the Sikh Community Italy in 2009. His dedication and hard work in establishing the community have undoubtedly contributed to the well-being and growth of the Sikh community in Italy.":language === 'punjabi'? 'ਸ: ਸੁਖਦੇਵ ਸਿੰਘ ਕੰਗ ਨੇ 2009 ਵਿੱਚ ਸਿੱਖ ਕਮਿਊਨਿਟੀ ਇਟਲੀ ਦੀ ਸਥਾਪਨਾ ਕੀਤੀ ਸੀ। ਭਾਈਚਾਰੇ ਦੀ ਸਥਾਪਨਾ ਵਿੱਚ ਉਨ੍ਹਾਂ ਦੀ ਲਗਨ ਅਤੇ ਸਖ਼ਤ ਮਿਹਨਤ ਨੇ ਬਿਨਾਂ ਸ਼ੱਕ ਇਟਲੀ ਵਿੱਚ ਸਿੱਖ ਭਾਈਚਾਰੇ ਦੀ ਭਲਾਈ ਅਤੇ ਵਿਕਾਸ ਵਿੱਚ ਯੋਗਦਾਨ ਪਾਇਆ ਹੈ।':"Il Sig. Sukhdev Singh Kang ha fondato la Comunità Sikh Italia nel 2009. La sua dedizione e il suo duro lavoro nella creazione della comunità hanno indubbiamente contribuito al benessere e alla crescita della comunità Sikh in Italia. "}
 <br/>
                                
                </div>
                                        <ul style={{color:'#001d23'}} className="list-unstyled about-one__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-comment"></span>
                                                </div>
                                                <div className="text">
                                                    <h4>{language==='english'? "Community Help & Support" : language==='punjabi' ? "ਭਾਈਚਾਰਕ ਮਦਦ ਅਤੇ ਸਮਰਥਨ" : "Community Help & Support"} </h4>
                                                    <p>{language === 'english'? "The Sikh community in Italy actively works to provide assistance and support to its members. This can include helping newcomers settle into the country, providing guidance on legal and administrative matters, offering language support, and providing a sense of community and belonging.":language === 'punjabi'? "ਇਟਲੀ ਵਿਚ ਸਿੱਖ ਭਾਈਚਾਰਾ ਆਪਣੇ ਮੈਂਬਰਾਂ ਨੂੰ ਸਹਾਇਤਾ ਅਤੇ ਸਹਾਇਤਾ ਪ੍ਰਦਾਨ ਕਰਨ ਲਈ ਸਰਗਰਮੀ ਨਾਲ ਕੰਮ ਕਰਦਾ ਹੈ। ਇਸ ਵਿੱਚ ਨਵੇਂ ਆਏ ਲੋਕਾਂ ਨੂੰ ਦੇਸ਼ ਵਿੱਚ ਵਸਣ ਵਿੱਚ ਮਦਦ ਕਰਨਾ, ਕਾਨੂੰਨੀ ਅਤੇ ਪ੍ਰਸ਼ਾਸਕੀ ਮਾਮਲਿਆਂ ਬਾਰੇ ਮਾਰਗਦਰਸ਼ਨ ਪ੍ਰਦਾਨ ਕਰਨਾ, ਭਾਸ਼ਾ ਸਹਾਇਤਾ ਦੀ ਪੇਸ਼ਕਸ਼ ਕਰਨਾ, ਅਤੇ ਭਾਈਚਾਰੇ ਅਤੇ ਸਬੰਧਤ ਦੀ ਭਾਵਨਾ ਪ੍ਰਦਾਨ ਕਰਨਾ ਸ਼ਾਮਲ ਹੋ ਸਕਦਾ ਹੈ।":"La comunità Sikh in Italia lavora attivamente per fornire assistenza e supporto ai suoi membri. Ciò può includere aiutare i nuovi arrivati a stabilirsi nel paese, fornire indicazioni su questioni legali e amministrative, offrire supporto linguistico e fornire un senso di comunità e appartenenza."}
 </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-comment"></span>
                                                </div>
                                                <div className="text">
                                                    <h4> {language==='english'? "Student Help" : language === 'punjabi'? "ਵਿਦਿਆਰਥੀ ਸਹਾਇਤਾ":"Aiuto agli studenti"} </h4>
                                                    <p>{language=== "english" ? 'Many Sikh students in Italy benefit from the support and guidance offered by the Sikh community. They provide mentoring, educational resources, and assistance in navigating the academic system. Additionally, the community often organizes events and workshops specifically tailored to the needs of Sikh students.': language === "punjabi"?"ਇਟਲੀ ਦੇ ਬਹੁਤ ਸਾਰੇ ਸਿੱਖ ਵਿਦਿਆਰਥੀ ਸਿੱਖ ਭਾਈਚਾਰੇ ਦੁਆਰਾ ਦਿੱਤੇ ਗਏ ਸਮਰਥਨ ਅਤੇ ਮਾਰਗਦਰਸ਼ਨ ਤੋਂ ਲਾਭ ਉਠਾਉਂਦੇ ਹਨ। ਉਹ ਅਕਾਦਮਿਕ ਪ੍ਰਣਾਲੀ ਨੂੰ ਨੈਵੀਗੇਟ ਕਰਨ ਵਿੱਚ ਸਲਾਹ, ਵਿਦਿਅਕ ਸਰੋਤ ਅਤੇ ਸਹਾਇਤਾ ਪ੍ਰਦਾਨ ਕਰਦੇ ਹਨ। ਇਸ ਤੋਂ ਇਲਾਵਾ, ਕਮਿਊਨਿਟੀ ਅਕਸਰ ਸਿੱਖ ਵਿਦਿਆਰਥੀਆਂ ਦੀਆਂ ਲੋੜਾਂ ਮੁਤਾਬਕ ਖਾਸ ਤੌਰ 'ਤੇ ਸਮਾਗਮਾਂ ਅਤੇ ਵਰਕਸ਼ਾਪਾਂ ਦਾ ਆਯੋਜਨ ਕਰਦੀ ਹੈ।":"molti studenti sikh in Italia beneficiano del supporto e della guida offerti dalla comunità sikh. Forniscono tutoraggio, risorse educative e assistenza nella navigazione nel sistema accademico. Inoltre, la comunità organizza spesso eventi e seminari specificamente adattati alle esigenze degli studenti sikh."} </p>
                                                </div>
                                            </li>
                                        
                                        </ul>
                                        {location.pathname === '/'?    <div className="about-one__bottom-video-box">
                                            <div className="about-one__btn-box">
                                                <a style={{textDecoration:'none'}} href="about" className="thm-btn about-one__btn">Read More</a>
                                            </div>
                                          
                                        </div> :null }

                                        {location.pathname !== '/' ?  <div style={{color:'#001d23'}} >
                                        {language === 'english' ? "These services and activities reflect the Sikh principle of 'Sarbat da Bhala,' which means the well-being and welfare of all. The Sikh community in Italy strives to live by this principle and make positive contributions to both their own community and the broader Italian society.":language === 'punjabi'? "ਇਹ ਸੇਵਾਵਾਂ ਅਤੇ ਗਤੀਵਿਧੀਆਂ 'ਸਰਬੱਤ ਦਾ ਭਲਾ' ਦੇ ਸਿੱਖ ਸਿਧਾਂਤ ਨੂੰ ਦਰਸਾਉਂਦੀਆਂ ਹਨ, ਜਿਸਦਾ ਅਰਥ ਹੈ ਸਭ ਦੀ ਭਲਾਈ ਅਤੇ ਭਲਾਈ। ਇਟਲੀ ਵਿਚ ਸਿੱਖ ਭਾਈਚਾਰਾ ਇਸ ਸਿਧਾਂਤ ਦੇ ਅਨੁਸਾਰ ਰਹਿਣ ਅਤੇ ਆਪਣੇ ਭਾਈਚਾਰੇ ਅਤੇ ਵਿਸ਼ਾਲ ਇਟਾਲੀਅਨ ਸਮਾਜ ਦੋਵਾਂ ਲਈ ਸਕਾਰਾਤਮਕ ਯੋਗਦਾਨ ਪਾਉਣ ਦੀ ਕੋਸ਼ਿਸ਼ ਕਰਦਾ ਹੈ।":"Questi servizi e attività riflettono il principio Sikh del 'Sarbat da Bhala', che significa il benessere e il benessere di tutti. La comunità Sikh in Italia si sforza di vivere secondo questo principio e di dare un contributo positivo sia alla propria comunità che alla più ampia società italiana."}  
                                         <br/> <br/>
                                        {language === 'english'?"However, it is possible that the Sikh community in Italy receives support or cooperation from local authorities or government entities for certain initiatives. This can include obtaining permits or permissions for events, accessing resources or facilities, or collaborating on projects related to social integration or interfaith dialogue.":language === 'punjabi'? "ਹਾਲਾਂਕਿ, ਇਹ ਸੰਭਵ ਹੈ ਕਿ ਇਟਲੀ ਵਿੱਚ ਸਿੱਖ ਭਾਈਚਾਰੇ ਨੂੰ ਕੁਝ ਪਹਿਲਕਦਮੀਆਂ ਲਈ ਸਥਾਨਕ ਅਧਿਕਾਰੀਆਂ ਜਾਂ ਸਰਕਾਰੀ ਸੰਸਥਾਵਾਂ ਤੋਂ ਸਮਰਥਨ ਜਾਂ ਸਹਿਯੋਗ ਪ੍ਰਾਪਤ ਹੋਵੇ। ਇਸ ਵਿੱਚ ਇਵੈਂਟਾਂ ਲਈ ਪਰਮਿਟ ਜਾਂ ਅਨੁਮਤੀਆਂ ਪ੍ਰਾਪਤ ਕਰਨਾ, ਸਰੋਤਾਂ ਜਾਂ ਸਹੂਲਤਾਂ ਤੱਕ ਪਹੁੰਚ ਕਰਨਾ, ਜਾਂ ਸਮਾਜਿਕ ਏਕੀਕਰਨ ਜਾਂ ਅੰਤਰ-ਧਰਮ ਸੰਵਾਦ ਨਾਲ ਸਬੰਧਤ ਪ੍ਰੋਜੈਕਟਾਂ ਵਿੱਚ ਸਹਿਯੋਗ ਕਰਨਾ ਸ਼ਾਮਲ ਹੋ ਸਕਦਾ ਹੈ।":"Tuttavia, è possibile che la comunità sikh in Italia riceva sostegno o collaborazione da parte di autorità locali o enti governativi per determinate iniziative. Ciò può includere l'ottenimento di permessi o autorizzazioni per eventi, l'accesso a risorse o strutture o la collaborazione a progetti relativi all'integrazione sociale o al dialogo interreligioso."} 
                                         <br/> <br/>
                                         {language === 'english' ? "Mr. Sukhdev Singh Kang's arrival in Italy in December 1992 and his subsequent efforts to help society and promote the Sikh religion demonstrate a personal commitment to service and community building. His love for helping others is a value deeply ingrained in Sikh teachings, which emphasize selfless service and compassion for all.":language === 'punjabi'? "ਸ: ਸੁਖਦੇਵ ਸਿੰਘ ਕੰਗ ਦਾ ਦਸੰਬਰ 1992 ਵਿਚ ਇਟਲੀ ਆਉਣਾ ਅਤੇ ਉਸ ਤੋਂ ਬਾਅਦ ਸਮਾਜ ਦੀ ਮਦਦ ਕਰਨ ਅਤੇ ਸਿੱਖ ਧਰਮ ਦੇ ਪ੍ਰਚਾਰ ਲਈ ਕੀਤੇ ਗਏ ਯਤਨ ਸੇਵਾ ਅਤੇ ਭਾਈਚਾਰਕ ਉਸਾਰੀ ਪ੍ਰਤੀ ਨਿੱਜੀ ਵਚਨਬੱਧਤਾ ਨੂੰ ਦਰਸਾਉਂਦੇ ਹਨ। ਦੂਸਰਿਆਂ ਦੀ ਮਦਦ ਕਰਨ ਲਈ ਉਸਦਾ ਪਿਆਰ ਸਿੱਖ ਸਿੱਖਿਆਵਾਂ ਵਿੱਚ ਡੂੰਘਾਈ ਨਾਲ ਜੁੜਿਆ ਹੋਇਆ ਇੱਕ ਮੁੱਲ ਹੈ, ਜੋ ਸਾਰਿਆਂ ਲਈ ਨਿਰਸਵਾਰਥ ਸੇਵਾ ਅਤੇ ਹਮਦਰਦੀ 'ਤੇ ਜ਼ੋਰ ਦਿੰਦਾ ਹੈ।":"L'arrivo di Sukhdev Singh Kang in Italia nel dicembre 1992 ei suoi successivi sforzi per aiutare la società e promuovere la religione sikh dimostrano un impegno personale per il servizio e la costruzione della comunità. Il suo amore per aiutare gli altri è un valore profondamente radicato negli insegnamenti sikh, che enfatizzano il servizio disinteressato e la compassione per tutti."} 
                                         <br/> <br/>
                                         {language === 'english' ? "It's wonderful to see individuals like Mr. Sukhdev Singh Kang contributing to the well-being of their community and making a positive impact in society. Their dedication and passion help foster a sense of unity, support, and cultural preservation within the Sikh community." : language === 'punjabi' ? "ਸੁਖਦੇਵ ਸਿੰਘ ਕੰਗ ਵਰਗੀਆਂ ਸ਼ਖ਼ਸੀਅਤਾਂ ਨੂੰ ਆਪਣੇ ਭਾਈਚਾਰੇ ਦੀ ਭਲਾਈ ਲਈ ਯੋਗਦਾਨ ਪਾਉਂਦੇ ਹੋਏ ਅਤੇ ਸਮਾਜ ਵਿੱਚ ਸਕਾਰਾਤਮਕ ਪ੍ਰਭਾਵ ਪਾਉਂਦੇ ਹੋਏ ਦੇਖਣਾ ਬਹੁਤ ਵਧੀਆ ਹੈ। ਉਹਨਾਂ ਦਾ ਸਮਰਪਣ ਅਤੇ ਜਨੂੰਨ ਸਿੱਖ ਭਾਈਚਾਰੇ ਵਿੱਚ ਏਕਤਾ, ਸਮਰਥਨ ਅਤੇ ਸੱਭਿਆਚਾਰਕ ਸੰਭਾਲ ਦੀ ਭਾਵਨਾ ਨੂੰ ਵਧਾਉਣ ਵਿੱਚ ਮਦਦ ਕਰਦਾ ਹੈ।":" È meraviglioso vedere persone come Sukhdev Singh Kang contribuire al benessere della loro comunità e avere un impatto positivo nella società. La loro dedizione e passione aiutano a promuovere un senso di unità, sostegno e conservazione culturale all'interno della comunità sikh."}
                                         </div> :null}
                                     
                                       

                                    </div>
                                </div>
                            </div>:
                            <div className="col-xl-15">
                                <div className="about-one__right">
                                    <div className="about-one__right-content">
                                        <div className="section-title text-left">
                                            <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                                            <span className="section-title__tagline">About Us</span>
                                           <div>
                                           <Button  onClick={()=>setLanguage('english')} style={ language === 'english'? {color:'white',backgroundColor:'#ff6f0f'}  : {color:'#ff6f0f',backgroundColor:'transparent'}}>English</Button>
                                            <Button onClick={()=>setLanguage('punjabi')}  style={ language === 'punjabi'? {color:'white',backgroundColor:'#ff6f0f'}  : {color:'#ff6f0f',backgroundColor:'transparent'}}>ਪੰਜਾਬੀ</Button>
                                            <Button  onClick={()=>setLanguage('italy')}  style={ language === 'italy'? {color:'white',backgroundColor:'#ff6f0f'}  : {color:'#ff6f0f',backgroundColor:'transparent'}}>italiano</Button>
                                           </div>
                                          
                                            </div>
                                           
                                            <h2 className="section-title__title">Indian Sikh Community Italy</h2>
                                        </div>
                                        <div style={{color:'#001d23'}} className="about-one__text"> {language === 'english' ? "Mr. Sukhdev Singh Kang founded the Sikh Community Italy in 2009. His dedication and hard work in establishing the community have undoubtedly contributed to the well-being and growth of the Sikh community in Italy.":language === 'punjabi'? 'ਸ: ਸੁਖਦੇਵ ਸਿੰਘ ਕੰਗ ਨੇ 2009 ਵਿੱਚ ਸਿੱਖ ਕਮਿਊਨਿਟੀ ਇਟਲੀ ਦੀ ਸਥਾਪਨਾ ਕੀਤੀ ਸੀ। ਭਾਈਚਾਰੇ ਦੀ ਸਥਾਪਨਾ ਵਿੱਚ ਉਨ੍ਹਾਂ ਦੀ ਲਗਨ ਅਤੇ ਸਖ਼ਤ ਮਿਹਨਤ ਨੇ ਬਿਨਾਂ ਸ਼ੱਕ ਇਟਲੀ ਵਿੱਚ ਸਿੱਖ ਭਾਈਚਾਰੇ ਦੀ ਭਲਾਈ ਅਤੇ ਵਿਕਾਸ ਵਿੱਚ ਯੋਗਦਾਨ ਪਾਇਆ ਹੈ।':"Il Sig. Sukhdev Singh Kang ha fondato la Comunità Sikh Italia nel 2009. La sua dedizione e il suo duro lavoro nella creazione della comunità hanno indubbiamente contribuito al benessere e alla crescita della comunità Sikh in Italia. "}
 <br/>
                                        <br/> {language === 'english' ? "Mr. Sukhdev Singh Kang's efforts in helping society and promoting the Sikh religion align with the values and principles of Sikhism, which emphasize selfless service and compassion towards others. Through his leadership and commitment, he has provided a platform for Sikhs in Italy to come together, support one another, and actively engage in community service. ":language ==='punjabi'? " ਸ੍ਰੀ ਸੁਖਦੇਵ ਸਿੰਘ ਕੰਗ ਦੇ ਸਮਾਜ ਦੀ ਮਦਦ ਕਰਨ ਅਤੇ ਸਿੱਖ ਧਰਮ ਨੂੰ ਅੱਗੇ ਵਧਾਉਣ ਲਈ ਕੀਤੇ ਯਤਨ ਸਿੱਖ ਧਰਮ ਦੀਆਂ ਕਦਰਾਂ-ਕੀਮਤਾਂ ਅਤੇ ਸਿਧਾਂਤਾਂ ਨਾਲ ਮੇਲ ਖਾਂਦੇ ਹਨ, ਜੋ ਦੂਜਿਆਂ ਪ੍ਰਤੀ ਨਿਰਸਵਾਰਥ ਸੇਵਾ ਅਤੇ ਹਮਦਰਦੀ 'ਤੇ ਜ਼ੋਰ ਦਿੰਦੇ ਹਨ। ਆਪਣੀ ਅਗਵਾਈ ਅਤੇ ਵਚਨਬੱਧਤਾ ਦੁਆਰਾ, ਉਸਨੇ ਇਟਲੀ ਦੇ ਸਿੱਖਾਂ ਨੂੰ ਇਕੱਠੇ ਹੋਣ, ਇੱਕ ਦੂਜੇ ਦਾ ਸਮਰਥਨ ਕਰਨ ਅਤੇ ਸਮਾਜ ਸੇਵਾ ਵਿੱਚ ਸਰਗਰਮੀ ਨਾਲ ਸ਼ਾਮਲ ਹੋਣ ਲਈ ਇੱਕ ਪਲੇਟਫਾਰਮ ਪ੍ਰਦਾਨ ਕੀਤਾ ਹੈ। " :"Gli sforzi del signor Sukhdev Singh Kang nell'aiutare la società e promuovere la religione sikh sono in linea con i valori e i principi del sikhismo, che enfatizzano il servizio disinteressato e la compassione verso gli altri. Attraverso la sua leadership e il suo impegno, ha fornito una piattaforma per i sikh in Italia per riunirsi, sostenersi a vicenda e impegnarsi attivamente nel servizio alla comunità."  }  <br/>
                                        <br/> {language === 'english' ? "The services and activities mentioned earlier, such as community help and support, student assistance, and initiatives to help those in need, are likely a result of Mr. Sukhdev Singh Kang's vision and the collective efforts of the Sikh Community Italy." : language === 'punjabi' ? "ਪਹਿਲਾਂ ਜ਼ਿਕਰ ਕੀਤੀਆਂ ਸੇਵਾਵਾਂ ਅਤੇ ਗਤੀਵਿਧੀਆਂ, ਜਿਵੇਂ ਕਿ ਕਮਿਊਨਿਟੀ ਮਦਦ ਅਤੇ ਸਹਾਇਤਾ, ਵਿਦਿਆਰਥੀ ਸਹਾਇਤਾ, ਅਤੇ ਲੋੜਵੰਦਾਂ ਦੀ ਮਦਦ ਲਈ ਪਹਿਲਕਦਮੀਆਂ, ਸੰਭਾਵਤ ਤੌਰ 'ਤੇ ਸ਼੍ਰੀ ਸੁਖਦੇਵ ਸਿੰਘ ਕੰਗ ਦੀ ਦੂਰਅੰਦੇਸ਼ੀ ਅਤੇ ਸਿੱਖ ਕਮਿਊਨਿਟੀ ਇਟਲੀ ਦੇ ਸਮੂਹਿਕ ਯਤਨਾਂ ਦਾ ਨਤੀਜਾ ਹਨ।" : "I servizi e le attività menzionate in precedenza, come l'aiuto e il sostegno della comunità, l'assistenza agli studenti e le iniziative per aiutare i bisognosi, sono probabilmente il risultato della visione del Sig. Sukhdev Singh Kang e degli sforzi collettivi della Comunità Sikh Italia." }
                                        
                                        <br/><br/>  {language === 'english' ? "  It's truly inspiring to see individuals like Mr. Sukhdev Singh Kang make a significant impact on the community, fostering a sense of unity, cultural preservation, and social responsibility. Their contributions play a vital role in shaping the Sikh community in Italy and promoting Sikh values in the broader society." : language === 'punjabi' ? "ਸੁਖਦੇਵ ਸਿੰਘ ਕੰਗ ਵਰਗੀਆਂ ਸ਼ਖ਼ਸੀਅਤਾਂ ਨੂੰ ਏਕਤਾ, ਸੱਭਿਆਚਾਰਕ ਸੰਭਾਲ ਅਤੇ ਸਮਾਜਿਕ ਜ਼ਿੰਮੇਵਾਰੀ ਦੀ ਭਾਵਨਾ ਪੈਦਾ ਕਰਦੇ ਹੋਏ, ਭਾਈਚਾਰੇ 'ਤੇ ਮਹੱਤਵਪੂਰਨ ਪ੍ਰਭਾਵ ਪਾਉਂਦੇ ਹੋਏ ਦੇਖਣਾ ਸੱਚਮੁੱਚ ਪ੍ਰੇਰਨਾਦਾਇਕ ਹੈ। ਉਨ੍ਹਾਂ ਦਾ ਯੋਗਦਾਨ ਇਟਲੀ ਦੇ ਸਿੱਖ ਭਾਈਚਾਰੇ ਨੂੰ ਢਾਲਣ ਅਤੇ ਵਿਸ਼ਾਲ ਸਮਾਜ ਵਿੱਚ ਸਿੱਖ ਕਦਰਾਂ-ਕੀਮਤਾਂ ਨੂੰ ਪ੍ਰਫੁੱਲਤ ਕਰਨ ਵਿੱਚ ਅਹਿਮ ਭੂਮਿਕਾ ਨਿਭਾਉਂਦਾ ਹੈ।" :"È davvero stimolante vedere individui come Sukhdev Singh Kang avere un impatto significativo sulla comunità, promuovendo un senso di unità, conservazione culturale e responsabilità sociale. I loro contributi svolgono un ruolo fondamentale nel plasmare la comunità sikh in Italia e promuovere i valori sikh nella società in generale."}
                                        
                                         <br/>
                                        <br/>{language ==='english' ? " That's wonderful to hear! The Indian Sikh community in Italy, like Sikh communities around the world, places a strong emphasis on community service and helping those in need. Here are some of the services and activities they typically engage in:":language === 'punjabi' ? "ਸੁਖਦੇਵ ਸਿੰਘ ਕੰਗ ਵਰਗੀਆਂ ਸ਼ਖ਼ਸੀਅਤਾਂ ਨੂੰ ਆਪਣੇ ਭਾਈਚਾਰੇ ਦੀ ਭਲਾਈ ਲਈ ਯੋਗਦਾਨ ਪਾਉਂਦੇ ਹੋਏ ਅਤੇ ਸਮਾਜ ਵਿੱਚ ਸਕਾਰਾਤਮਕ ਪ੍ਰਭਾਵ ਪਾਉਂਦੇ ਹੋਏ ਦੇਖਣਾ ਬਹੁਤ ਵਧੀਆ ਹੈ। ਉਹਨਾਂ ਦਾ ਸਮਰਪਣ ਅਤੇ ਜਨੂੰਨ ਸਿੱਖ ਭਾਈਚਾਰੇ ਵਿੱਚ ਏਕਤਾ, ਸਮਰਥਨ ਅਤੇ ਸੱਭਿਆਚਾਰਕ ਸੰਭਾਲ ਦੀ ਭਾਵਨਾ ਨੂੰ ਵਧਾਉਣ ਵਿੱਚ ਮਦਦ ਕਰਦਾ ਹੈ।":"È meraviglioso da sentire! La comunità sikh indiana in Italia, come le comunità sikh in tutto il mondo, pone una forte enfasi sul servizio alla comunità e sull'aiuto ai bisognosi. Ecco alcuni dei servizi e delle attività in cui si impegnano in genere:"} </div>
                                        <ul style={{color:'#001d23'}} className="list-unstyled about-one__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-comment"></span>
                                                </div>
                                                <div className="text">
                                                    <h4>{language==='english'? "Community Help & Support" : language==='punjabi' ? "ਭਾਈਚਾਰਕ ਮਦਦ ਅਤੇ ਸਮਰਥਨ" : "Community Help & Support"} </h4>
                                                    <p>{language === 'english'? "The Sikh community in Italy actively works to provide assistance and support to its members. This can include helping newcomers settle into the country, providing guidance on legal and administrative matters, offering language support, and providing a sense of community and belonging.":language === 'punjabi'? "ਇਟਲੀ ਵਿਚ ਸਿੱਖ ਭਾਈਚਾਰਾ ਆਪਣੇ ਮੈਂਬਰਾਂ ਨੂੰ ਸਹਾਇਤਾ ਅਤੇ ਸਹਾਇਤਾ ਪ੍ਰਦਾਨ ਕਰਨ ਲਈ ਸਰਗਰਮੀ ਨਾਲ ਕੰਮ ਕਰਦਾ ਹੈ। ਇਸ ਵਿੱਚ ਨਵੇਂ ਆਏ ਲੋਕਾਂ ਨੂੰ ਦੇਸ਼ ਵਿੱਚ ਵਸਣ ਵਿੱਚ ਮਦਦ ਕਰਨਾ, ਕਾਨੂੰਨੀ ਅਤੇ ਪ੍ਰਸ਼ਾਸਕੀ ਮਾਮਲਿਆਂ ਬਾਰੇ ਮਾਰਗਦਰਸ਼ਨ ਪ੍ਰਦਾਨ ਕਰਨਾ, ਭਾਸ਼ਾ ਸਹਾਇਤਾ ਦੀ ਪੇਸ਼ਕਸ਼ ਕਰਨਾ, ਅਤੇ ਭਾਈਚਾਰੇ ਅਤੇ ਸਬੰਧਤ ਦੀ ਭਾਵਨਾ ਪ੍ਰਦਾਨ ਕਰਨਾ ਸ਼ਾਮਲ ਹੋ ਸਕਦਾ ਹੈ।":"La comunità Sikh in Italia lavora attivamente per fornire assistenza e supporto ai suoi membri. Ciò può includere aiutare i nuovi arrivati a stabilirsi nel paese, fornire indicazioni su questioni legali e amministrative, offrire supporto linguistico e fornire un senso di comunità e appartenenza."}
 </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-comment"></span>
                                                </div>
                                                <div className="text">
                                                    <h4> {language==='english'? "Student Help" : language === 'punjabi'? "ਵਿਦਿਆਰਥੀ ਸਹਾਇਤਾ":"Aiuto agli studenti"} </h4>
                                                    <p>{language=== "english" ? 'Many Sikh students in Italy benefit from the support and guidance offered by the Sikh community. They provide mentoring, educational resources, and assistance in navigating the academic system. Additionally, the community often organizes events and workshops specifically tailored to the needs of Sikh students.': language === "punjabi"?"ਇਟਲੀ ਦੇ ਬਹੁਤ ਸਾਰੇ ਸਿੱਖ ਵਿਦਿਆਰਥੀ ਸਿੱਖ ਭਾਈਚਾਰੇ ਦੁਆਰਾ ਦਿੱਤੇ ਗਏ ਸਮਰਥਨ ਅਤੇ ਮਾਰਗਦਰਸ਼ਨ ਤੋਂ ਲਾਭ ਉਠਾਉਂਦੇ ਹਨ। ਉਹ ਅਕਾਦਮਿਕ ਪ੍ਰਣਾਲੀ ਨੂੰ ਨੈਵੀਗੇਟ ਕਰਨ ਵਿੱਚ ਸਲਾਹ, ਵਿਦਿਅਕ ਸਰੋਤ ਅਤੇ ਸਹਾਇਤਾ ਪ੍ਰਦਾਨ ਕਰਦੇ ਹਨ। ਇਸ ਤੋਂ ਇਲਾਵਾ, ਕਮਿਊਨਿਟੀ ਅਕਸਰ ਸਿੱਖ ਵਿਦਿਆਰਥੀਆਂ ਦੀਆਂ ਲੋੜਾਂ ਮੁਤਾਬਕ ਖਾਸ ਤੌਰ 'ਤੇ ਸਮਾਗਮਾਂ ਅਤੇ ਵਰਕਸ਼ਾਪਾਂ ਦਾ ਆਯੋਜਨ ਕਰਦੀ ਹੈ।":"molti studenti sikh in Italia beneficiano del supporto e della guida offerti dalla comunità sikh. Forniscono tutoraggio, risorse educative e assistenza nella navigazione nel sistema accademico. Inoltre, la comunità organizza spesso eventi e seminari specificamente adattati alle esigenze degli studenti sikh."} </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-comment"></span>
                                                </div>
                                                <div className="text">
                                                    <h4> {language === "english"? "Latest Community Activities" :language==='punjabi'? "ਨਵੀਨਤਮ ਭਾਈਚਾਰਕ ਗਤੀਵਿਧੀਆਂ" :"Ultime attività della comunità" }</h4>
                                                    <p>{language === 'english' ? "The Sikh community in Italy organizes a range of activities and events to promote cultural awareness, community bonding, and education. These can include religious gatherings, cultural festivals, seminars, workshops, and sports events. These activities serve as opportunities for the community to come together, celebrate their traditions, and raise awareness about Sikhism." :language ==="punjabi" ? "ਇਟਲੀ ਵਿੱਚ ਸਿੱਖ ਭਾਈਚਾਰਾ ਸੱਭਿਆਚਾਰਕ ਜਾਗਰੂਕਤਾ, ਭਾਈਚਾਰਕ ਸਾਂਝ, ਅਤੇ ਸਿੱਖਿਆ ਨੂੰ ਉਤਸ਼ਾਹਿਤ ਕਰਨ ਲਈ ਕਈ ਗਤੀਵਿਧੀਆਂ ਅਤੇ ਸਮਾਗਮਾਂ ਦਾ ਆਯੋਜਨ ਕਰਦਾ ਹੈ। ਇਨ੍ਹਾਂ ਵਿੱਚ ਧਾਰਮਿਕ ਇਕੱਠ, ਸੱਭਿਆਚਾਰਕ ਤਿਉਹਾਰ, ਸੈਮੀਨਾਰ, ਵਰਕਸ਼ਾਪ ਅਤੇ ਖੇਡ ਸਮਾਗਮ ਸ਼ਾਮਲ ਹੋ ਸਕਦੇ ਹਨ। ਇਹ ਗਤੀਵਿਧੀਆਂ ਭਾਈਚਾਰੇ ਲਈ ਇਕੱਠੇ ਹੋਣ, ਆਪਣੀਆਂ ਪਰੰਪਰਾਵਾਂ ਮਨਾਉਣ, ਅਤੇ ਸਿੱਖ ਧਰਮ ਬਾਰੇ ਜਾਗਰੂਕਤਾ ਪੈਦਾ ਕਰਨ ਦੇ ਮੌਕੇ ਵਜੋਂ ਕੰਮ ਕਰਦੀਆਂ ਹਨ।":" la comunità sikh in Italia organizza una serie di attività ed eventi per promuovere la consapevolezza culturale, il legame con la comunità e l'istruzione. Questi possono includere raduni religiosi, festival culturali, seminari, workshop ed eventi sportivi. Queste attività servono come opportunità per la comunità di riunirsi, celebrare le proprie tradizioni e aumentare la consapevolezza sul sikhismo."}</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-comment"></span>
                                                </div>
                                                <div className="text">
                                                    <h4>  {language === 'english'?"Helping Those in Need" : language === 'punjabi' ? "ਲੋੜਵੰਦਾਂ ਦੀ ਮਦਦ ਕਰਨਾ":"Aiutare i bisognosi"} </h4>
                                                    <p>{language === 'english'? "Sikhs place great importance on selfless service, known as 'seva.' The Sikh community in Italy actively engages in seva by providing assistance to people who are in need, regardless of their background or religion. This can include organizing food drives, providing meals to the homeless through langar (community kitchen), and participating in charitable initiatives to support vulnerable communities.":language==='punjabi'? "ਸਿੱਖ ਨਿਰਸਵਾਰਥ ਸੇਵਾ ਨੂੰ ਬਹੁਤ ਮਹੱਤਵ ਦਿੰਦੇ ਹਨ, ਜਿਸਨੂੰ 'ਸੇਵਾ' ਕਿਹਾ ਜਾਂਦਾ ਹੈ। ਇਟਲੀ ਵਿਚ ਸਿੱਖ ਭਾਈਚਾਰਾ ਸਰਗਰਮੀ ਨਾਲ ਲੋੜਵੰਦ ਲੋਕਾਂ ਨੂੰ ਸਹਾਇਤਾ ਪ੍ਰਦਾਨ ਕਰਕੇ ਸੇਵਾ ਵਿਚ ਰੁੱਝਿਆ ਹੋਇਆ ਹੈ, ਭਾਵੇਂ ਉਹਨਾਂ ਦਾ ਪਿਛੋਕੜ ਜਾਂ ਧਰਮ ਕੋਈ ਵੀ ਹੋਵੇ। ਇਸ ਵਿੱਚ ਫੂਡ ਡਰਾਈਵ ਦਾ ਆਯੋਜਨ ਕਰਨਾ, ਲੰਗਰ (ਕਮਿਊਨਿਟੀ ਰਸੋਈ) ਰਾਹੀਂ ਬੇਘਰਿਆਂ ਨੂੰ ਖਾਣਾ ਮੁਹੱਈਆ ਕਰਵਾਉਣਾ, ਅਤੇ ਕਮਜ਼ੋਰ ਭਾਈਚਾਰਿਆਂ ਦੀ ਸਹਾਇਤਾ ਲਈ ਚੈਰੀਟੇਬਲ ਪਹਿਲਕਦਮੀਆਂ ਵਿੱਚ ਹਿੱਸਾ ਲੈਣਾ ਸ਼ਾਮਲ ਹੋ ਸਕਦਾ ਹੈ।":"i sikh attribuiscono grande importanza al servizio disinteressato, noto come 'seva'. La comunità Sikh in Italia si impegna attivamente nel seva fornendo assistenza alle persone bisognose, indipendentemente dal loro background o religione. Ciò può includere l'organizzazione di raccolte di cibo, la fornitura di pasti ai senzatetto attraverso il langar (cucina comunitaria) e la partecipazione a iniziative di beneficenza a sostegno delle comunità vulnerabili."} </p>
                                                </div>
                                            </li>
                                        </ul>
                                        {location.pathname === '/'?    <div className="about-one__bottom-video-box">
                                            <div className="about-one__btn-box">
                                                <a href="about" className="thm-btn about-one__btn">Read More</a>
                                            </div>
                                          
                                        </div> :null }

                                        {location.pathname !== '/' ?  <div style={{color:'#001d23'}} >
                                        {language === 'english' ? "These services and activities reflect the Sikh principle of 'Sarbat da Bhala,' which means the well-being and welfare of all. The Sikh community in Italy strives to live by this principle and make positive contributions to both their own community and the broader Italian society.":language === 'punjabi'? "ਇਹ ਸੇਵਾਵਾਂ ਅਤੇ ਗਤੀਵਿਧੀਆਂ 'ਸਰਬੱਤ ਦਾ ਭਲਾ' ਦੇ ਸਿੱਖ ਸਿਧਾਂਤ ਨੂੰ ਦਰਸਾਉਂਦੀਆਂ ਹਨ, ਜਿਸਦਾ ਅਰਥ ਹੈ ਸਭ ਦੀ ਭਲਾਈ ਅਤੇ ਭਲਾਈ। ਇਟਲੀ ਵਿਚ ਸਿੱਖ ਭਾਈਚਾਰਾ ਇਸ ਸਿਧਾਂਤ ਦੇ ਅਨੁਸਾਰ ਰਹਿਣ ਅਤੇ ਆਪਣੇ ਭਾਈਚਾਰੇ ਅਤੇ ਵਿਸ਼ਾਲ ਇਟਾਲੀਅਨ ਸਮਾਜ ਦੋਵਾਂ ਲਈ ਸਕਾਰਾਤਮਕ ਯੋਗਦਾਨ ਪਾਉਣ ਦੀ ਕੋਸ਼ਿਸ਼ ਕਰਦਾ ਹੈ।":"Questi servizi e attività riflettono il principio Sikh del 'Sarbat da Bhala', che significa il benessere e il benessere di tutti. La comunità Sikh in Italia si sforza di vivere secondo questo principio e di dare un contributo positivo sia alla propria comunità che alla più ampia società italiana."}  
                                         <br/> <br/>
                                        {language === 'english'?"However, it is possible that the Sikh community in Italy receives support or cooperation from local authorities or government entities for certain initiatives. This can include obtaining permits or permissions for events, accessing resources or facilities, or collaborating on projects related to social integration or interfaith dialogue.":language === 'punjabi'? "ਹਾਲਾਂਕਿ, ਇਹ ਸੰਭਵ ਹੈ ਕਿ ਇਟਲੀ ਵਿੱਚ ਸਿੱਖ ਭਾਈਚਾਰੇ ਨੂੰ ਕੁਝ ਪਹਿਲਕਦਮੀਆਂ ਲਈ ਸਥਾਨਕ ਅਧਿਕਾਰੀਆਂ ਜਾਂ ਸਰਕਾਰੀ ਸੰਸਥਾਵਾਂ ਤੋਂ ਸਮਰਥਨ ਜਾਂ ਸਹਿਯੋਗ ਪ੍ਰਾਪਤ ਹੋਵੇ। ਇਸ ਵਿੱਚ ਇਵੈਂਟਾਂ ਲਈ ਪਰਮਿਟ ਜਾਂ ਅਨੁਮਤੀਆਂ ਪ੍ਰਾਪਤ ਕਰਨਾ, ਸਰੋਤਾਂ ਜਾਂ ਸਹੂਲਤਾਂ ਤੱਕ ਪਹੁੰਚ ਕਰਨਾ, ਜਾਂ ਸਮਾਜਿਕ ਏਕੀਕਰਨ ਜਾਂ ਅੰਤਰ-ਧਰਮ ਸੰਵਾਦ ਨਾਲ ਸਬੰਧਤ ਪ੍ਰੋਜੈਕਟਾਂ ਵਿੱਚ ਸਹਿਯੋਗ ਕਰਨਾ ਸ਼ਾਮਲ ਹੋ ਸਕਦਾ ਹੈ।":"Tuttavia, è possibile che la comunità sikh in Italia riceva sostegno o collaborazione da parte di autorità locali o enti governativi per determinate iniziative. Ciò può includere l'ottenimento di permessi o autorizzazioni per eventi, l'accesso a risorse o strutture o la collaborazione a progetti relativi all'integrazione sociale o al dialogo interreligioso."} 
                                         <br/> <br/>
                                         {language === 'english' ? "Mr. Sukhdev Singh Kang's arrival in Italy in December 1992 and his subsequent efforts to help society and promote the Sikh religion demonstrate a personal commitment to service and community building. His love for helping others is a value deeply ingrained in Sikh teachings, which emphasize selfless service and compassion for all.":language === 'punjabi'? "ਸ: ਸੁਖਦੇਵ ਸਿੰਘ ਕੰਗ ਦਾ ਦਸੰਬਰ 1992 ਵਿਚ ਇਟਲੀ ਆਉਣਾ ਅਤੇ ਉਸ ਤੋਂ ਬਾਅਦ ਸਮਾਜ ਦੀ ਮਦਦ ਕਰਨ ਅਤੇ ਸਿੱਖ ਧਰਮ ਦੇ ਪ੍ਰਚਾਰ ਲਈ ਕੀਤੇ ਗਏ ਯਤਨ ਸੇਵਾ ਅਤੇ ਭਾਈਚਾਰਕ ਉਸਾਰੀ ਪ੍ਰਤੀ ਨਿੱਜੀ ਵਚਨਬੱਧਤਾ ਨੂੰ ਦਰਸਾਉਂਦੇ ਹਨ। ਦੂਸਰਿਆਂ ਦੀ ਮਦਦ ਕਰਨ ਲਈ ਉਸਦਾ ਪਿਆਰ ਸਿੱਖ ਸਿੱਖਿਆਵਾਂ ਵਿੱਚ ਡੂੰਘਾਈ ਨਾਲ ਜੁੜਿਆ ਹੋਇਆ ਇੱਕ ਮੁੱਲ ਹੈ, ਜੋ ਸਾਰਿਆਂ ਲਈ ਨਿਰਸਵਾਰਥ ਸੇਵਾ ਅਤੇ ਹਮਦਰਦੀ 'ਤੇ ਜ਼ੋਰ ਦਿੰਦਾ ਹੈ।":"L'arrivo di Sukhdev Singh Kang in Italia nel dicembre 1992 ei suoi successivi sforzi per aiutare la società e promuovere la religione sikh dimostrano un impegno personale per il servizio e la costruzione della comunità. Il suo amore per aiutare gli altri è un valore profondamente radicato negli insegnamenti sikh, che enfatizzano il servizio disinteressato e la compassione per tutti."} 
                                         <br/> <br/>
                                         {language === 'english' ? "It's wonderful to see individuals like Mr. Sukhdev Singh Kang contributing to the well-being of their community and making a positive impact in society. Their dedication and passion help foster a sense of unity, support, and cultural preservation within the Sikh community." : language === 'punjabi' ? "ਸੁਖਦੇਵ ਸਿੰਘ ਕੰਗ ਵਰਗੀਆਂ ਸ਼ਖ਼ਸੀਅਤਾਂ ਨੂੰ ਆਪਣੇ ਭਾਈਚਾਰੇ ਦੀ ਭਲਾਈ ਲਈ ਯੋਗਦਾਨ ਪਾਉਂਦੇ ਹੋਏ ਅਤੇ ਸਮਾਜ ਵਿੱਚ ਸਕਾਰਾਤਮਕ ਪ੍ਰਭਾਵ ਪਾਉਂਦੇ ਹੋਏ ਦੇਖਣਾ ਬਹੁਤ ਵਧੀਆ ਹੈ। ਉਹਨਾਂ ਦਾ ਸਮਰਪਣ ਅਤੇ ਜਨੂੰਨ ਸਿੱਖ ਭਾਈਚਾਰੇ ਵਿੱਚ ਏਕਤਾ, ਸਮਰਥਨ ਅਤੇ ਸੱਭਿਆਚਾਰਕ ਸੰਭਾਲ ਦੀ ਭਾਵਨਾ ਨੂੰ ਵਧਾਉਣ ਵਿੱਚ ਮਦਦ ਕਰਦਾ ਹੈ।":" È meraviglioso vedere persone come Sukhdev Singh Kang contribuire al benessere della loro comunità e avere un impatto positivo nella società. La loro dedizione e passione aiutano a promuovere un senso di unità, sostegno e conservazione culturale all'interno della comunità sikh."}
                                         </div> :null}
                                     
                                       

                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </section>
            </>
        )

}

export default AboutOne