import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link} from 'react-router-dom';
import { selectHomeFbPosts, setsinglePostDetails } from '../../utility/Redux/facebook';

const BlogLatestPost = () => {
        const dispatch = useDispatch()
        
        let publicUrl = process.env.PUBLIC_URL+'/'
        const recentPosts = useSelector(selectHomeFbPosts)
  
        return (
            <>
                <div className="sidebar__single sidebar__post">
                    <h3 className="sidebar__title">Recent Post</h3>
                    <ul className="sidebar__post-list list-unstyled">
                    {recentPosts.map((recentPost,index) =>{
                      
                        if(index <= 2){
                   return (
                          <li onClick={()=>{
                           
                                        dispatch(setsinglePostDetails(recentPost))
                                        localStorage.setItem('singlePostDetails', JSON.stringify(recentPost))
                                    }}>
                          <div  className="sidebar__post-image">
                              <img src={recentPost.image} alt="" />
                          </div>
                          <div className="sidebar__post-content">
                              <h3>
                                  <span className="sidebar__post-content-meta"><i className="far fa-user-circle"></i> Admin</span>
                                  <Link style={{textDecoration:'none'}} to={process.env.PUBLIC_URL + `/blog-details`}>{recentPost.message}</Link>
                              </h3>
                          </div>
                      </li>

                    )}else{
                        return null  }})
                    }
                    
                    
                    
                      
                    
                    </ul>
                </div>
            </>
        )
    
}

export default BlogLatestPost;