import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { selectHomeFbPosts, setsinglePostDetails } from '../../utility/Redux/facebook';



const  FooterOne = () =>  {

    const gallery = useSelector(selectHomeFbPosts)
    const [galleryPost,setGalleryPost] = useState([])
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
   
   
    const getGallery = () =>{
        let list = []
      
        for (let index = 0; index < 12 ; index++) {
            
            list.push( {
                ...gallery[index]
            } )

            setGalleryPost(list)
                
            
        }
    }



        const $ = window.$;

        if ($(".footer-widget-one__twitter-feed-content").length) {
            $(".footer-widget-one__twitter-feed-content").owlCarousel({
                loop: true,
                autoplay: true,
                margin: 30,
                nav: false,
                dots: true,
                smartSpeed: 500,
                autoplayTimeout: 10000,
                navText: ["<span class=\"fa fa-angle-left\"></span>","<span class=\"fa fa-angle-right\"></span>"],
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 1
                    },
                    991: {
                        items: 1
                    },
                    1200: {
                        items: 1
                    }
                }
            });
        }
        
          
       useEffect(()=>{
        getGallery()
       },[gallery])
      
       
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <footer  className="site-footer-one">
                    <div className="site-footer-one__top">
                        <div className="container">
                            <div className="row">

                                <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                                    <div className="footer-widget__column footer-widget-one__about">
                                        <div style={{flexDirection:'row' ,display:'flex',}}>
                                        <div className="footer-widget-one__about-logo">
                                            <Link style={{textDecoration:'none'}} to={process.env.PUBLIC_URL + `/`}><img style={{width:60,height:60}} src={publicUrl+"assets/images/icon/logoP.png"} alt="" /></Link>
                                        </div>
                                       </div>
                                        <p className="footer-widget-one__about-text">
                                        Empowering Sikh voices in Italy, advocating for religious freedom, and cultivating harmony within diverse communities
                                        </p>
                                        <ul className="list-unstyled footer-widget-one__list">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-place"></span>
                                                </div>
                                                <div className="text">
                                                    <p>
 VIA MALDOFF N, 01 , Gavardo, Italy.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-envelope"></span>
                                                </div>
                                                <div style={{whiteSpace:'normal',}} className="text">
                                                    <p><a style={{textDecoration:'none'}} href="mailto:indiansikhcommunityitaly@yahoo.com">indiansikhcommunityitaly@yahoo.com</a></p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-call"></span>
                                                </div>
                                                <div className="text">
                                                    <p><a style={{textDecoration:'none'}} href="tel:393890463253">39 389 046 3253</a></p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                                    <div className="footer-widget__column footer-widget-one__gallery clearfix">
                                        <h3 className="footer-widget-one__title">Gallery</h3>
                                        <ul className="footer-widget-one__gallery-list list-unstyled clearfix">
                                        
                                        {galleryPost.length >0 && galleryPost.map((post,index) =>  <li key={index} 
                                         onClick={()=>{
                                           navigate('/blog-details')
                                           dispatch(setsinglePostDetails(post))
                                           localStorage.setItem('singlePostDetails', JSON.stringify(post))

                                         }}
                                        >
                                                <div className="footer-widget-one__gallery-img">
                                                    <img style={{width:'80px',height:'80px'}}  src={post.image} alt="" onError={(e)=>{
                                                        e.target.src = publicUrl+"assets/images/icon/logoP.png"
                                                    }} />
                                                 
                                                </div>
                                            </li> )}
                                           
                                          
                                          
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                                    <div className="footer-widget__column footer-widget-one__latest-works clearfix">
                                        <h3 className="footer-widget-one__title">Latest News</h3>
                                        <ul className="footer-widget-one__latest-works-list list-unstyled clearfix">
                                            <li>
                                                <div className="footer-widget-one__latest-works-content">
                                                    <h4 className="footer-widget-one__latest-works-title">
                                                        <Link style={{textDecoration:'none'}} to={process.env.PUBLIC_URL + `/blog-details`}>PM Narendra Modi receives PM Giorgia Meloni of Italy in a ceremonial</Link>
                                                    </h4>
                                                    <p className="footer-widget-one__latest-works-date">
                                                        March 02, 2023
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-widget-one__latest-works-content">
                                                    <h4 className="footer-widget-one__latest-works-title">
                                                        <Link style={{textDecoration:'none'}} to={process.env.PUBLIC_URL + `/blog-details`}>At the Milan Consulate General it is possible to apply the OCI card via DHL.</Link>
                                                    </h4>
                                                    <p className="footer-widget-one__latest-works-date">
                                                        Jan 21, 2021
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <div className="site-footer__bottom">
                        <div className="container">
                            <div className="site-footer__bottom-inner">
                                <p className="site-footer__bottom-text">© 2023 Copyright Indian Sikh Community Italy | Powered by <a style={{textDecoration:'none'}} href="https://www.geniusoffice.com/" target="_blank" >genius office</a></p>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
                            }

        export default FooterOne