import { Button } from '@mui/material';
import React, { useState } from 'react';

const ContactForm = () => {
         const [name,setName] = useState('')
         const [email,setEmail] = useState('')
         const [phone,setPhone] = useState('')
         const [message,setMessage] = useState('')
         const [subject,setSubject] = useState('')
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="contact-page">
                    <div className="contact-page-bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/contact-page-bg.jpg)'}}></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <div className="contact-page__left">
                                    <div className="section-title text-left">
                                        <span className="section-title__tagline"></span>
                                        <h6 style={{fontSize:25}} className="section-title__title">Enhancing Connections Through Conversational <br/>  Contact Forms </h6 >
                                    </div>
                                    <div className="contact-page__form">
                                        <form action="#" className="comment-one__form contact-form-validated" novalidate="novalidate">
                                            <div className="row">
                                               
                                                <div className="col-xl-6">
                                                    <div className="comment-form__input-box">
                                                        <input value={name} onChange={(t)=> setName( t.target.value)} type="text" placeholder="Your name" name="name" />
                                                    </div>
                                                </div>

                                                {/* <div className="col-xl-6">
                                                    <div className="comment-form__input-box">
                                                        <input value={email} onChange={(t)=> setEmail(t.target.value)} type="email" placeholder="Email address" name="email" />
                                                    </div>
                                                </div> */}
                                                <div className="col-xl-6">
                                                    <div className="comment-form__input-box">
                                                        <input  value={phone} onChange={(t)=>setPhone(t.target.value)} type="text" placeholder="Phone number" name="Phone" />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6">
                                                    <div className="comment-form__input-box">
                                                        <input value={subject} onChange={(t)=> setSubject(t.target.value)  } type="text" placeholder="Subject" name="Subject" />
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="comment-form__input-box text-message-box">
                                                        <textarea value={message} onChange={(t) =>setMessage(t.target.value)} name="message" placeholder="Write Your msg"></textarea>
                                                    </div>
                                                    <div className="comment-form__btn-box">
                                                    <a style={{textDecoration:'none',color:'white'}} href={`mailto:indiansikhcommunityitaly@yahoo.com?subject=${ encodeURIComponent(subject) }&body=${ encodeURIComponent(message)+'%0D%0A'+`from ${name} (${phone})`  }`}>
                                                          <button type="button" className="thm-btn comment-form__btn">  Send a message </button>
                                                         </a>
                                                     
                                                    </div>
                                            
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {
                                window.innerWidth > 768 ? <div className="col-xl-6 col-lg-6">
                                <div className="contact-page__right">
                                    <div className="contact-page__img">
                                        <img src={publicUrl+"assets/images/gallery/contactUs.jpg"} alt="" />
                                        <div className="contact-page__img-shape">
                                            <img src={publicUrl+"assets/images/shapes/contact-page-img-shape.png"} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div> :null
                            }
                         
                        </div>
                    </div>
                </section>
            </>
        )

}

export default ContactForm