import React from 'react';
import axios from 'axios';
import { sethomeFbPosts } from '../utility/Redux/facebook';

const Facebook3Posts = async (dispatch) => {

   try {
   
      axios.post('/api/fb_Posts/list_fb_Posts',{
          "from_date":"2023-05-01",
          "to_date":"2023-06-03"
      },{
          headers:  {
                              'Content-Type': 'application/json',
                              "Authorization": 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2VtYWlsIjoiYmFscmFqQGdlbml1c29mZmljZS5jb20iLCJpYXQiOjE2ODU3MTIyNDksImV4cCI6MTY4NTcxNTg0OX0.lNjsYkqY3a8He5USmg4n4Kre14SsePcMq6WjzFyJTOQ'
                          },
    
      }).then((res)=>{
        
          if(!res.data.error){
      
            dispatch(sethomeFbPosts(res.data.response))
            return res.data.response
          }}
          
          )
          
        
      .catch(err =>{
  
          console.log(err)
        return null

      } )
  
      } catch (error) {
        console.error(error);
        return null
      }
    };



  
  
 


export default Facebook3Posts;
