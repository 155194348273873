import { configureStore } from "@reduxjs/toolkit";
import facebook from "./facebook";

const Store = configureStore({
    reducer: {
        facebook: facebook,
    },

  });

  export default Store;