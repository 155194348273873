import axios from 'axios';
import React from 'react'
import moment from 'moment';
import { sethomeFbPosts } from '../utility/Redux/facebook';
import { App_id } from '../utility/comman';


const GetPosts = (dispatch,from,to,) => {

  
 
        axios
          .post(
            '/api/list_Fb_Posts/list_Fb_Posts',
            {
              from_date: from,
              to_date: to,
              app_id:App_id,
            },
            {
              headers: {
                'Content-Type': 'application/json',
            },
            }
          )
          .then((res) => {
         console.log(res);
    
            if (!res.data.error) {
               
             let approvedList = []
             res.data.response.forEach((item) => {
               if (item.approved === 'YES') {
                 approvedList.push(item)
               }
             })
            //  console.log(approvedList)
            ;
         
             const reversedList = approvedList.reverse()
             const filteredImageList = reversedList.filter(item => item.message !== 'null' && item.image !== null
                )
          
            //  if(reversedList.length>0){
                dispatch(sethomeFbPosts(filteredImageList))
            //  }
            localStorage.setItem('homefbPosts', JSON.stringify(filteredImageList))
          
           
            } else {
          
              console.log(res.data.error);
            }
          })
          
          .catch((err) => {
            console.log(err);
          });
}

export default GetPosts