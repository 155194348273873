import React from 'react';
import {Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination, Navigation } from "swiper";

export default class HeroOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="main-slider">
                    <Swiper 
                        loop={true}
                        slidesPerView={1}
                        effect="fade"
                        autoplay={{delay:   5000}}
                        modules={[EffectFade, Pagination, Navigation]}
                        pagination={{
                            el: "#main-slider-pagination",
                            type: "bullets",
                            clickable: true
                        }}
                        navigation={{
                            nextEl: "#main-slider__swiper-button-next",
                            prevEl: "#main-slider__swiper-button-prev"
                        }}
                        className="swiper-container thm-swiper__slider">

                        <div  className="swiper-wrapper">

                                         {/* Start Swiper Slide Single */}
                                         <SwiperSlide className="swiper-slide">
                                <div className="image-layer" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/banner11.jpg)'}}></div>
                                <div className="main-slider-shape-1"></div>
                                <div className="main-slider-shape-2"></div>
                                <div className="main-slider-shape-3 zoominout"></div>
                                <div className="main-slider-shape-4 zoominout-2"></div>
                                <div className="main-slider-shape-5">
                                    <img src={publicUrl+"assets/images/shapes/main-slider-shape-5.png"} alt="" />
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-7">
                                            <div className="main-slider__content">
                                                <h2>
                                                Connecting  <span>Sikhs </span> in Italy  <br />
                                                    
                                                  </h2>
                                                <p>Celebrating Heritage, Building Bridges<br />  </p>
                                                <Link  style={{textDecoration:'none'}} to={process.env.PUBLIC_URL + `/about`} className="thm-btn main-slider__btn">About Us</Link>
                                                <div className="main-slider-arrow">
                                               
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* End Swiper Slide Single */}

                              {/* Start Swiper Slide Single */}
                              <SwiperSlide className="swiper-slide">
                                <div className="image-layer" style={{  backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/banner9.jpg)'}}></div>
                                <div className="main-slider-shape-1">
                           
                                </div>
                                <div className="main-slider-shape-2">
                              
                                </div>
                                <div className="main-slider-shape-3 zoominout">
                               
                                </div>
                                <div className="main-slider-shape-4 zoominout-2">
                               
                                </div>
                                <div className="main-slider-shape-5">
                                <img src={publicUrl+"assets/images/shapes/main-slider-shape-5.png"} alt="" />
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-7">
                                            <div className="main-slider__content">
                                            <div style={{fontSize:44,fontWeight:"bolder",lineHeight:1,color:'white'}}>  Sikh   <span>Presence</span> in Italy<br /></div>
                                                <p> Connecting Souls, Inspiring Collective Progress </p>
                                                <Link style={{textDecoration:'none'}} to={process.env.PUBLIC_URL + `/about`} className="thm-btn main-slider__btn">About Us</Link>
                                                <div className="main-slider-arrow">
                                                    <img src={publicUrl+"assets/images/shapes/main-slider-shape-1.png"} className="float-bob-x" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* End Swiper Slide Single */}

                            {/* Start Swiper Slide Single */}
                            <SwiperSlide className="swiper-slide">
                                <div className="image-layer" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/banner4.jpg)'}}></div>
                                <div className="main-slider-shape-1"></div>
                                <div className="main-slider-shape-2"></div>
                                <div className="main-slider-shape-3 zoominout"></div>
                                <div className="main-slider-shape-4 zoominout-2"></div>
                                <div className="main-slider-shape-5">
                                    <img src={publicUrl+"assets/images/shapes/main-slider-shape-5.png"} alt="" />
                                </div>
                                
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-7">
                                            <div className="main-slider__content">
                                                <div style={{fontSize:40,fontWeight:"bolder",lineHeight:1,color:'white'}}>Uniting    <span>Sikhs</span> in Italy <br /> Sharing Cultural Pride.</div>
                                                <p>Vibrant Sikh community uniting Indian Sikhs in Italy.
<br /> Events, festivals, and connections celebrating our heritage.<br/>
Embrace roots, promote pride, forge lifelong bonds.
Join us,<br/> enrich lives, strengthen Sikh diaspora in Italy.
</p>
                                                <Link style={{textDecoration:'none'}} to={process.env.PUBLIC_URL + `/about`} className="thm-btn main-slider__btn">About Us</Link>
                                                <div className="main-slider-arrow">
                                           
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* End Swiper Slide Single */}

                          

               

                        </div>

                        <div className="swiper-pagination" id="main-slider-pagination"></div>

                        <div className="main-slider__nav">
                            <div className="swiper-button-prev" id="main-slider__swiper-button-next">
                                <i className="fa fa-long-arrow-alt-left"></i>
                            </div>
                            <div className="swiper-button-next" id="main-slider__swiper-button-prev">
                                <i className="fa fa-long-arrow-alt-right"></i>
                            </div>
                        </div>

                    </Swiper>
                </section>
            </>
        )
    }
}