import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { selectFromDate, selectHomeFbPosts, selectToDate, setToDate, setfromDate, setsinglePostDetails } from '../../utility/Redux/facebook';
import moment from 'moment';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import GetPosts from '../../functions/getPosts';
import { Button } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../src/custome-bootstrap.css';
import Lottie from "lottie-react";
import noData from '../../../src/utility/json/noData.json'
import logo from './../../utility/images/logo.png'
 const  RecentEventsOne = () => {
  
        let publicUrl = process.env.PUBLIC_URL+'/'
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
        const dispatch = useDispatch()
        const from = useSelector(selectFromDate)
        const to = useSelector(selectToDate)
        const navigate = useNavigate()

        const posts = useSelector(selectHomeFbPosts)
       
      

        useEffect(()=>{
          
        GetPosts(dispatch,from,to)
        },[from,to])

       
        return (
            <>
                <section  className="recent-event">
                    <div  className="container">
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}} className="section-title text-center">
                            {/* <span className="section-title__tagline">Our </span> */}
                            {window.innerWidth > 768 ?  <h2 className="section-title__title">      Activities for the year {moment(to).format('YYYY')}</h2> :null}
                           
                      
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:5}}>
                            <Button onClick={()=>{
                                   dispatch(setfromDate(moment().startOf('year').format('YYYY-MM-DD')))
                                dispatch(setToDate(moment().format('YYYY-MM-DD')))
                            }} style={{marginRight:5}} variant= { moment(to).format('YYYY') === moment().format('YYYY') ? 'contained' : 'outlined'}><div style={{marginLeft:5}}>{moment().format('YYYY')}</div> </Button>
                             <Button onClick={()=>{
                               dispatch(setfromDate(moment(moment().subtract(1,'year').format('YYYY')).startOf('year').format('YYYY-MM-DD')))
                                dispatch(setToDate( moment(moment().subtract(1,'year').format('YYYY')).endOf('year').format('YYYY-MM-DD')))
                            }} style={{marginRight:5}} variant= { moment(to).format('YYYY') === moment().subtract(1,'year').format('YYYY') ? 'contained' : 'outlined'}> <div style={{marginLeft:5}}>{moment().subtract(1,'year').format('YYYY')}</div> </Button>
                              <Button onClick={()=>{
                               dispatch(setfromDate(moment(moment().subtract(2,'year').format('YYYY')).startOf('year').format('YYYY-MM-DD')))
                                dispatch(setToDate( moment(moment().subtract(2,'year').format('YYYY')).endOf('year').format('YYYY-MM-DD')))
                            }} style={{marginRight:5}} variant= { moment(to).format('YYYY') === moment().subtract(2,'year').format('YYYY') ? 'contained' : 'outlined'}><div style={{marginLeft:5}}>{moment().subtract(2,'year').format('YYYY')}</div> </Button>
                           
                            {window.innerWidth > 768 ? 
                            <div>
                           
                           <Button onClick={()=>{
                               dispatch(setfromDate(moment(moment().subtract(3,'year').format('YYYY')).startOf('year').format('YYYY-MM-DD')))
                                dispatch(setToDate( moment(moment().subtract(3,'year').format('YYYY')).endOf('year').format('YYYY-MM-DD')))
                            }} style={{marginRight:5}} variant= { moment(to).format('YYYY') === moment().subtract(3,'year').format('YYYY') ? 'contained' : 'outlined'}><div style={{marginLeft:5,marginRight:5}}>{moment().subtract(3,'year').format('YYYY')}</div> </Button> </div> : null}
                            <DropdownButton    id="dropdown-basic-button " title="Select Year" variant='secondary' >
                           
                            {years.map((year) => (

        <Dropdown.Item onClick={()=>{
          dispatch(setfromDate(moment(year, 'YYYY').startOf('year').format('YYYY-MM-DD')))
          dispatch(setToDate(moment(year, 'YYYY').endOf('year').format('YYYY-MM-DD')))
        } } key={year} href="#">{year}</Dropdown.Item>
      ))}
    </DropdownButton>
                        </div>
                        </div>

                        <div className="row" >
                        {posts.map((item) => {
                                   return (
                                    <div onClick={()=>{
                                        dispatch(setsinglePostDetails(item))
                                        navigate('/blog-details')
                                        localStorage.setItem('singlePostDetails', JSON.stringify(item))
                                    }}  className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-delay="100ms">
                                    <div className="recent-event__single">
                                    <div className="recent-event__img">
                                        <img  src={item.image} alt="" onError={(e)=>{
                                           e.target.src = logo
                                        }} />
                                        <div className="recent-event__btn-box">
                                            <Link to={process.env.PUBLIC_URL + `/events`} className="thm-btn recent-event__btn">Read More</Link>
                                        </div>
                                    </div>
                                    <div className="recent-event__content">
                                        <ul className="list-unstyled recent-event__meta">
                                            <li>
                                                <p> <span className="icon-calendar"></span> {moment(item.date).format('YYYY-MM-DD') }</p>
                                            </li>
                                            {/* <li>
                                                <p> <span className="icon-back-in-time"></span> 04:00 am </p>
                                            </li> */}
                                        </ul>
                                        {/* <h3 className="recent-event__title"><Link to={process.env.PUBLIC_URL + `/events`}>Event of Shares</Link></h3> */}
                                        <p className="recent-event__text">{item.message}</p>
                                    </div>
                                </div>
                                </div>
 
                                   ) 
                                })}
                                {posts.length < 1 ? <div style={{flexDirection:'row',display:'flex',justifyContent:'center',marginTop:60,marginBottom:100,}}> <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center'}}> <Lottie style={{height:'auto',width:'20%',marginBottom:30}} animationData={noData} loop={true}  />


                            <div style={{color:'#6c757d',fontWeight:600}}>    There are no activities for the selected year. </div>

</div>
 </div>:null}
                          
                        
                        </div>
                    </div>
      
                </section>
            </>
        )

}
export default RecentEventsOne 