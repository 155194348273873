import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const localHomeFbpost =  JSON.parse(localStorage.getItem("homefbPosts")) 
const localSinglePost = JSON.parse(localStorage.getItem("singlePostDetails")) 


export const Facebook = createSlice({
  name: "facebook",
  initialState: {
   homeFbPosts: localHomeFbpost ? localHomeFbpost : [],
   singlePostDetails: localSinglePost? localSinglePost : [],
   selectededPhoto:'',
   firstDate: moment().subtract(1,'year').format('YYYY-MM-DD'),
   lastDate: moment().format('YYYY-MM-DD')
  
  },
  reducers: {
  sethomeFbPosts(state,action){
    state.homeFbPosts = action.payload;
  },
  setfromDate(state,action){
    state.firstDate = action.payload;
  },
  setToDate(state,action){
    state.lastDate = action.payload;
  },
  setSelectedPhoto(state,action){
    state.selectededPhoto = action.payload;},
  setsinglePostDetails(state,action){
    state.singlePostDetails = action.payload}
  
}
});

export default Facebook.reducer;

export const {
    sethomeFbPosts,
    setfromDate,
    setToDate,
    setSelectedPhoto,
    setsinglePostDetails
} = Facebook.actions;

export const selectHomeFbPosts = (state) => state.facebook.homeFbPosts
export const selectSinglePostDetails =   (state)=> state.facebook.singlePostDetails
export const selectSelectedPhoto = (state) => state.facebook.selectededPhoto
export const selectFromDate = ( state) => state.facebook.firstDate
export const selectToDate = ( state) => state.facebook.lastDate