import React from 'react';
import {Link} from 'react-router-dom';
import BlogCategory from '../blog-sidebar/BlogCategory';
import BlogComments from '../blog-sidebar/BlogComments';
import BlogLatestPost from '../blog-sidebar/BlogLatestPost';
import BlogQuery from '../blog-sidebar/BlogQuery';
import BlogSearch from '../blog-sidebar/BlogSearch';
import BlogTags from '../blog-sidebar/BlogTags';
import { useSelector } from 'react-redux';
import { selectSinglePostDetails } from '../../utility/Redux/facebook';
import ReactPlayer from 'react-player'
import moment from 'moment';

 const SingleBlogDetails = ()=> {

        let publicUrl = process.env.PUBLIC_URL+'/'
        const singleBlogDetails = useSelector(selectSinglePostDetails)
        
        return (
            <>
                <section className="blog-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-lg-7">
                                <div className="blog-details__left">
                                    <div className="blog-details__img">
                                        <img src={singleBlogDetails.image} alt="" />
                                        <div className="blog-details__date">
                                            <p> {moment(singleBlogDetails.date).format("DD")} <br /> {moment(singleBlogDetails.date).format("MMM")}</p>
                                        </div>
                                    </div>
                                    <div className="blog-details__content">
                                        {/* <ul className="blog-details__meta list-unstyled">
                                            <li><Link to={process.env.PUBLIC_URL + `/blog-details`}><i className="far fa-comment"></i><span>(2)</span></Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/blog-details`}><i className="far fa-thumbs-up"></i><span>(2)</span></Link></li>
                                        </ul> */}
                                        <h3 className="blog-details__title">About Post</h3>
                                     
                                    
                                 
                                        <div style={{marginTop:20}} > {singleBlogDetails.message} </div>

                                        {singleBlogDetails.video === "null" || singleBlogDetails.video === null ? null : <div style={{marginTop:'20px'}}>  <ReactPlayer 
                                     
                                     playing={true} 
                                     width={'400px'}
                                     url={singleBlogDetails.video} 
                                     controls={true} /> </div>  }
                                       
                                    </div>
                                
                                  
                                    {/* <div className="blog-details__bottom">
                                        <p className="blog-details__tags">
                                            <span>Tags</span>
                                            <a href="#">Fundraising</a>
                                            <a href="#">Charity</a>
                                        </p>
                                        <div className="blog-details__social-list">
                                            <a href="#"><i className="fab fa-twitter"></i></a>
                                            <a href="#"><i className="fab fa-facebook"></i></a>
                                            <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                            <a href="#"><i className="fab fa-instagram"></i></a>
                                        </div>
                                    </div> */}

                                    
                                    {/* <div className="author-one">
                                        <div className="author-one__image">
                                            <img src={publicUrl+"assets/images/blog/author-1-1.jpg"} alt="" />
                                        </div>
                                        <div className="author-one__content">
                                            <h3>Hover Cameron</h3>
                                            <p>Lorem ipsum is simply free text by copytyping refreshing. Neque porro est qui
                                                dolorem ipsum quia quaed veritatis et quasi architecto.</p>
                                        </div>
                                    </div> */}
                                    {/* <div className="comment-one">
                                        <h3 className="comment-one__title">2 Comments</h3>
                                        <div className="comment-one__single">
                                            <div className="comment-one__image">
                                                <img src={publicUrl+"assets/images/blog/comment-1-1.jpg"} alt="" />
                                            </div>
                                            <div className="comment-one__content">
                                                <h3>Kevin Martin</h3>
                                                <p>It has survived not only five centuries, but also the leap into electronic
                                                    typesetting unchanged. It was popularised in the sheets containing lorem
                                                    ipsum is simply free text.</p>
                                                <span>February 03. 2022</span>
                                                <Link to={process.env.PUBLIC_URL + `/blog-details`} className="thm-btn comment-one__btn">Reply</Link>
                                            </div>
                                        </div>
                                        <div className="comment-one__single">
                                            <div className="comment-one__image">
                                                <img src={publicUrl+"assets/images/blog/comment-1-2.jpg"} alt="" />
                                            </div>
                                            <div className="comment-one__content">
                                                <h3>Jessica Brown</h3>
                                                <p>It has survived not only five centuries, but also the leap into electronic
                                                    typesetting unchanged. It was popularised in the sheets containing lorem
                                                    ipsum is simply free text.</p>
                                                <span>February 03. 2022</span>
                                                <Link to={process.env.PUBLIC_URL + `/blog-details`} className="thm-btn comment-one__btn">Reply</Link>
                                            </div>
                                        </div>
                                    </div> */}

                                
                                    {/* <div className="comment-form">
                                        <h3 className="comment-form__title">Leave A Reply</h3>
                                        <form action="#" className="comment-one__form contact-form-validated" novalidate="novalidate">
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="comment-form__input-box">
                                                        <input type="text" placeholder="Your Name" name="name" />
                                                    </div>
                                                </div>
                                                <div className="col-xl-12">
                                                    <div className="comment-form__input-box">
                                                        <input type="email" placeholder="Email Address" name="email" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="comment-form__input-box comment-form__textarea">
                                                        <textarea name="message" placeholder="Write a Message"></textarea>
                                                    </div>
                                                    <div className="comment-form__btn-box">
                                                        <button type="submit" className="thm-btn comment-form__btn">Post Comment</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                <div className="sidebar">
                               
                               
                                    <BlogLatestPost />
                                    <BlogQuery />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
export default SingleBlogDetails