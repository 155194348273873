import React from 'react';

export default class ContactMap extends React.Component {
    render(){
        return (
            <>
                <section className="contact-page-google-map">
                    <iframe
                        title="Address"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2792.3248435577666!2d10.434670887718745!3d45.58401415087018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4781854e12aaa2e5%3A0x1dffb822e234412c!2sVia%20Maldoff%2C%201%2C%2025085%20Gavardo%20BS%2C%20Italy!5e0!3m2!1sen!2sin!4v1686205004416!5m2!1sen!2sin"
                        className="contact-page-google-map__one" allowfullscreen></iframe>

                </section>
            </>
        )
    }
}