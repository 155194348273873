import axios from 'axios';
import React from 'react'

const TestLogin = () => {

    const handleFacebookLogin = async () => {
        try {
          const response = await axios.get('/auth/facebook');
        
          // Redirect the user to the provided URL in the response data
        //   window.location.href = response.data;
        } catch (error) {
          console.error('Error logging in with Facebook:', error);
        }
      };

  return (
    <div>  <button onClick={handleFacebookLogin}>Login with Facebook</button></div>
  )
}

export default TestLogin