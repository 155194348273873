import React from 'react';
import { Link } from 'react-router-dom';

const BlogQuery = () => {
        
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <div   className="sidebar__single sidebar__support">
                    <div className="sidebar__support-bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/gallery/g7.jpg)'}}></div>
                    <h3 className="sidebar__suppot-title">Have Any Query?</h3>
                    <p className="sidebar__suppot-text">Reach out to us by sending a mail to get in touch.</p>
                    <div className="sidebar__support-btn-box">
                        <Link to={'/contact'} >
                        <button type="submit" className="thm-btn sidebar__support-btn">Send Message</button>
                        </Link>
                    </div>
                </div>
            </>
        )
    }

export default BlogQuery;