import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import CreateIcon from '@mui/icons-material/Create';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ClearIcon from '@mui/icons-material/Clear';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [selectedMenu, setSelectedMenu] = React.useState('create_post');
  const navigate = useNavigate()
  const location = useLocation()
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' ,height:window.innerHeight,overflow:'hidden'}}>
      <CssBaseline />
      <AppBar sx={{backgroundColor:'#FF6F0F',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingRight:4}} position="fixed" open={open} >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' ,}) }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerClose}
            edge="start"
            sx={{ mr: 2, ...(!open && { display: 'none' ,}) }}
          >
            <ClearIcon />
             </IconButton>
          <Typography variant="h6" noWrap component="div">
           Indian Sikh Community Italy
          </Typography>
        </Toolbar>
      
        <PowerSettingsNewIcon /> 
      </AppBar>
      <Drawer
        sx={{
         
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
         
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {/* <DrawerHeader sx={{backgroundColor:'black'}}>
        <div style={{color:'#ff6f0f',fontSize:18,width:'100%',textAlign:'center'}}>Admin</div>
          <IconButton sx={{color:'white'}}  onClick={handleDrawerClose}>
          
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader> */}

      
          <DrawerHeader sx={{backgroundColor:'black',display:'flex',flexDirection:'row',justifyContent:'center',paddingY:3}}>
           
            <div  style={!open ? {display:'hidden'}:{display:'flex'}}>
              <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',width:'100%',}} >
               <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                <img
                  alt=""
                  src={require('../utility/images/GOROUND2.png')}
                 style={{width:'60px',height:'auto'}}
                />
                <img
                  alt=""
                  src={require('../utility/images/GO92X12.png')}
                  style={{width:'100px',height:'auto'}}
                />
                <div
                  style={{ fontSize: 9, marginTop: -1 ,display:'flex',flexDirection:'row',justifyContent:'center',color:'white'}}
                 
                >
                  Since 1994
                </div>
                </div>
              </div>
            </div>
            
          </DrawerHeader>
   
        <Divider />
        <List style={{backgroundColor:'black',height:'100%',display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
        <div>
          {[ 'Create Post','Approve Post'].map((text, index) => (
          
            <ListItem onClick={()=>{
                {text === 'Approve Post' && navigate('approve_post');  }
                {text === 'Create Post' && navigate('create_post');  }
                setSelectedMenu(text)
            }} sx={ selectedMenu === text ? {color:'#ff6f0f'}:{color:'white'}} key={text} disablePadding>
              <ListItemButton sx={ selectedMenu === text ? {color:'#ff6f0f'}:{color:'white'}}>
                <ListItemIcon sx={ selectedMenu === text ? {color:'#ff6f0f'}:{color:'white'}}>
                 
                 {text === 'Approve Post' && <CheckBoxOutlineBlank /> }
                 {text === 'Create Post' && <CreateIcon /> }
                 

                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
          </div>

<ListItem  sx={ {color:'white'}} key={'Logout'} disablePadding>
              <ListItemButton sx={ {color:'white'}}>
                <ListItemIcon sx={ {color:'white'}}>
                 
                 <PowerSettingsNewIcon /> 
          
                 

                </ListItemIcon>
                <ListItemText primary={'Logout'} />
              </ListItemButton>
            </ListItem>
        </List>
   
 
      </Drawer>
      <Main open={open} sx={{overflow:'scroll'}} >
        <DrawerHeader />
          <Outlet/>
      </Main>
    </Box>
  );
}