import React from 'react';
import { Link } from 'react-router-dom';

export default class CounterTwo extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($('.time-countdown').length) {
          $('.time-countdown').each(function () {
            var Self = $(this);
            var countDate = Self.data('countdown-time'); // getting date
      
            Self.countdown(countDate, function (event) {
              $(this).html('<h2>' + event.strftime('%D : %H : %M : %S') + '</h2>');
            });
          });
        };
      
      
        if ($('.time-countdown-two').length) {
          $('.time-countdown-two').each(function () {
            var Self = $(this);
            var countDate = Self.data('countdown-time'); // getting date
      
            Self.countdown(countDate, function (event) {
              $(this).html('<li> <div className="box"> <span className="days">' + event.strftime('%D') + '</span> <span className="timeRef">days</span> </div> </li> <li> <div className="box"> <span className="hours">' + event.strftime('%H') + '</span> <span className="timeRef clr-1">hours</span> </div> </li> <li> <div className="box"> <span className="minutes">' + event.strftime('%M') + '</span> <span className="timeRef clr-2">min</span> </div> </li> <li> <div className="box"> <span className="seconds">' + event.strftime('%S') + '</span> <span className="timeRef clr-3">sec</span> </div> </li>');
            });
          });
        };      
          
    }
    render(){
        return (
            <>
                <section  className="up-comming-events">
                    <div  className="container">
                        <div  className="row">
                            <div  className="col-xl-12">
                                <div style={{backgroundColor:'#edf0f5'}}  className="up-comming-events__inner wow fadeInUp" data-wow-delay="100ms">
                                    <div className="row">
                                        <div  className="col-xl-15">
                                            <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}} className="up-comming-events__left">
                                                <div  className="up-comming-events__content">
                                                    <h3 className="up-comming-events__title">Proudly carrying the spirit of the Guru,</h3>
                                                   
                                                      
                                                        <p>
                                                        The Sikh community in Italy shines as a beacon of unity, resilience, and faith.
                                                        </p>
                                                 
                                                </div>
                                            </div>
                                        </div>

                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
              
            </>
        )
    }
}